"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';

import ImageWithFallback from "@/components/ImageWithFallback/ImageWithFallback";
import DPIcon from "../DPIcon/DPIcon";
import BuyNow from "./BuyNow";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { linkUrl, purify } from "@/helpers";
import TextMarquee from "../TextMarquee/TextMarquee";
import { Navigation } from "swiper/modules";
import { formatENetValues, formatNumber, getECommerceId, insertENetValues } from "@/helpers/formatters";
import { findAndStopAllVideos } from "../HomeSlider/functions";
import VideoDialog from "../VideoPlayer/VideoDialog";
import _e from "@/helpers/texts";
import { durationMinutes } from "@/helpers/fetchers/package";
import { productDynamicValues, productSpecialButtons } from "@/helpers/fetchers/constants";

export default function PackageCard({ data, alwaysShowPricing, sizingFix }: {
  readonly data: Record<string, any>,
  readonly alwaysShowPricing?: boolean,
  readonly sizingFix?: boolean,
}) {
  const fallbackImage = `${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/webgorsel/tyt-tum-dersler.png`;
  const videoLogoUrl = `${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/images/global/videoplay-icon.svg`;

  const [ isLoaded, setIsLoaded ] = useState(false);

  /* ENET */
  const enet_values = formatENetValues({ ...(data?.curriculums?.credits ?? {}), ...{ Sezon: data.season, 'Paket Adı': data.caption } });
  /* -o- */

  /* Video Url Fallbacks for special items */
  const propertiesWithFallbackVideos = [ ... (data?.detail_props?.length ? data.detail_props : data.properties) ];
  propertiesWithFallbackVideos.forEach((item: Record<string,any>) => {
    switch(item?.special_button) {
    case 'Çözücü':
      if(!item?.video_url) item.video_url = 'tanitim/cozemediginiz-sorulari-cozen-uygulama/media.m3u8';
      break;
    case 'Şimdi Anladım':
      if(!item?.video_url) item.video_url = 'tanitim/simdi-anladim/media.m3u8';
      break;
    case 'Koçum Yanımda':
      if(!item?.video_url) item.video_url = 'tanitim/kocum-yanimda/media.m3u8';
      break;
    case 'Online Eğitim':
      if(!item?.video_url) item.video_url = 'tanitim/yapay-zeka/media.m3u8';
      break;
    }
  });

  const [ properties, setProperties ] = useState<Record<string,any>[]>(propertiesWithFallbackVideos?.filter((item: any) => {
    if(!data?.detail_props?.length && (!item?.view_type || item?.view_type === 'Kart')) return false;
    
    if(item?.special_button) {
      if(Object.keys(data?.curriculums?.credits).includes(productSpecialButtons[item.special_button])) return true;
      return false;
    }
    if(item?.bg_image_url) return true;
    
    if(item?.dynamic_value) {
      if(
        Object.keys(productDynamicValues).includes(item.dynamic_value)
        && data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
      ) {
        return true;
      } else return false;
    }

    return true;
  }) ?? []);
  
  const selectProperty = (id: number) => {
    findAndStopAllVideos();
    setProperties((current) => {
      return current.map((item) => {
        item.dialogActive = item.id === id;
        return item;
      });
    });
  };
  
  const deselectProperty = () => {
    setProperties((current) => {
      return current.map((item) => {
        item.dialogActive = false;
        return item;
      });
    });
  };

  const highlights: { id: number, url: string, caption: string }[] = [];
  const highlightCheck: string[] = [];
  let hlSelector = data?.properties; // Which list to select highlights from
  if(data?.thumbnails?.length) hlSelector = data?.thumbnails;

  hlSelector?.forEach((item: any) => {
    if(
      highlights.length < 3
      && item?.bg_image_url
    ) {
      if(!highlightCheck.includes(item.bg_image_url)) {
        highlightCheck.push(item.bg_image_url);
        highlights.push({ id: item.id, url: linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/attribute/', item.bg_image_url) ?? '', caption: item?.caption ?? '' });
      }
    }
  });

  const [ winSize, setWinSize ] = useState<number>(0);
  
  useEffect(() => {
    const onResize = () => {
      setWinSize(() => {
        let size = 0;
        if(window?.innerWidth >= 640) size = 1;
        if(window?.innerWidth >= 768) size = 2;
        if(window?.innerWidth >= 1024) size = 3;
        if(window?.innerWidth >= 1280) size = 4;
        
        if(sizingFix) {
          const w = window?.innerWidth > 120 && window?.innerWidth < 640 ? ((document?.body?.clientWidth ?? window?.innerWidth) - 72) + 'px' : '100%';
          document?.querySelector('.__swp-container-fixer')?.setAttribute('style', `width: ${w}`);
          document?.querySelector('.__marquee-container-fixer')?.setAttribute('style', `width: ${w}`);
        }
  
        return size;
      });
    };
  
    onResize();
    
    setIsLoaded(true);

    window?.addEventListener('resize', onResize);

    return () => { window?.removeEventListener('resize', onResize); };
  }, [ sizingFix ]);

  return (
    <div className={`bg-white rounded-lg p-5 md:p-8 shadow-md mt-2 md:mt-4 ${isLoaded ? 'opacity-100 transition-opacity duration-300' : 'opacity-0'}`}>
      <div className="relative flex flex-col">
        <div className="relative">
          <div className="relative flex justify-start items-stretch flex-wrap">
            <div
              className="relative self-start w-full lg:w-5/12 before:relative before:block aspect-video lg:aspect-square leading-[0px] rounded-lg overflow-hidden"
              style={{ backgroundColor: data.image_bg_color }}
            >
              {
                data?.vertical_image_url && data?.horizontal_image_url ? (
                  <>
                    <Image
                      src={winSize < 3 ? (linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.horizontal_image_url) ?? '') : (linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.vertical_image_url) ?? '')}
                      alt={data?.caption ?? 'Doping Hafıza'}
                      fill={true}
                      loading="lazy" //! priority={true}
                      sizes="100%"
                      style={{ objectFit: 'cover', objectPosition: 'center' }}
                    />
                  </>
                ) : (
                  <>
                    <div className="absolute z-[1] top-0 right-0 bottom-0 left-0">
                      <ImageWithFallback
                        src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.image_url)}
                        fallback={fallbackImage}
                        alt={data?.caption ?? 'Doping Hafıza'}
                        fill={true}
                        loading="lazy" //! priority={true}
                        sizes="100%"
                        style={{ objectFit: winSize < 3 ? 'contain' : 'cover', objectPosition: winSize < 2 ? (highlights.length ? 'left center' : 'center') : 'center' }}
                      />
                    </div>
                  </>
                )
              }

              {
                !(data?.vertical_image_url && data?.horizontal_image_url && winSize < 3) ? (
                  <div className="absolute w-1/3 max-w-[120px] flex flex-col justify-center items-stretch gap-1 z-[2] top-0 right-0 bottom-0 p-2 md:hidden">
                    {
                      highlights.map((item: { id: number, url: string, caption: string }) => (
                        <div key={item.id} className="relative p-1 bg-black/10 rounded-md flex-grow-1 flex-shrink-1">
                          <div className="relative before:relative before:block before:pb-[56.25%]">
                            <Image
                              src={item.url}
                              alt={item?.caption ?? 'Doping Hafıza'}
                              fill={true}
                              sizes="100%"
                              style={{ objectFit: 'contain', objectPosition: 'center' }}
                            />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : (<></>)
              }
            </div>

            <div className="w-full lg:w-7/12 pt-5 ps-0 lg:pt-0 lg:ps-7 flex flex-col items-start justify-between">
              <div className="mb-2">
                <div className="flex items-center flex-wrap gap-2">
                  {
                    data.detail_tags?.map((tag: Record<string,any>, index: number) => {
                      return (
                        <span
                          key={tag.id}
                          className="flex items-center rounded-md text-white py-2 px-3 text-sm font-semibold"
                          style={{
                            backgroundColor: tag?.color ? tag.color : ((index % 2) ? 'var(--green-RYB)' : 'var(--amber)')
                          }}
                        >
                          { tag?.icon && <DPIcon icon={tag.icon} className="me-1" /> }
                          { tag.caption }
                        </span>
                      );
                    })
                  }
                </div>

                <h2 className="block mt-5 mb-3 text-2xl font-bold leading-normal text-charcoal text-start">
                  { data.caption }
                </h2>

                <div className="text-[13px] leading-7 text-charcoal text-start" dangerouslySetInnerHTML={{ __html: insertENetValues(data?.content ?? '', enet_values) }}></div>
              </div>
              
              {
                winSize >= 4 && (
                  <BuyNow className="mt-2 w-full" comingSoon={data?.prevent_sales && data?.coming_soon} price={data?.price ?? 0} cancelledPrice={data?.cancelled_price} external_id={data?.external_id} ecommerceId={getECommerceId(data) ?? '0'} caption={data?.caption ?? ''} categoryName={data?.package_category?.caption ?? ''} />
                )
              }
            </div>

            {
              winSize < 4 && alwaysShowPricing ? (
                <BuyNow className="mt-2 w-full" comingSoon={data?.prevent_sales && data?.coming_soon} price={data?.price ?? 0} cancelledPrice={data?.cancelled_price} external_id={data?.external_id} ecommerceId={getECommerceId(data) ?? '0'} caption={data?.caption ?? ''} categoryName={data?.package_category?.caption ?? ''} />
              ) : (<></>)
            }
          </div>
        </div>
        
        {
          properties?.length > 0 && (
            <div className="relative">
              {
                data?.marquee && (
                  <div className="bg-medium-purple mt-3 rounded-sm __marquee-container-fixer">
                    <TextMarquee
                      className="text-white text-xs py-1 px-2"
                      text={data.marquee}
                    />
                  </div>
                )
              }

              <div className="relative">
                <div className="swiper-content __swp-container-fixer relative block py-2 px-4 bg-medium-purple rounded-lg mt-3">
                  <Swiper
                    breakpoints={{
                      0: {
                        slidesPerView: 'auto',
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1280: {
                        slidesPerView: 5,
                      },
                    }}
                    navigation={{
                      nextEl: '.swiper-button-next-properties',
                      prevEl: '.swiper-button-prev-properties',
                    }}
                    modules={[Navigation]}
                    className="flex items-stretch"
                  >
                    {
                      properties?.map((item: any) => {
                        return (
                          <SwiperSlide
                            key={item.id}
                            className={`!flex justify-center items-stretch p-3 !h-auto select-none flex-grow-0 flex-shrink-0 !w-[216px] lg:!w-1/4 xl:!w-1/5`}
                          >
                            <div className="relative flex justify-center overflow-hidden rounded-lg min-h-[84px] px-3 py-3 bg-black/30 flex-grow text-white">
                              
                              {
                                item?.video_url && (
                                  <div
                                    className="absolute flex justify-center items-center top-0 right-0 bottom-0 left-0 opacity-0 bg-black/20 transition-all duration-300 hover:opacity-100 cursor-pointer z-10"
                                    onClick={ () => selectProperty(item.id) }
                                  >
                                    <div className="relative w-10 h-10">
                                      <Image
                                        src={videoLogoUrl}
                                        alt={item?.caption ? `${item.caption} ${item.subcap ?? ''}` : 'Doping Hafıza'}
                                        fill={true}
                                        sizes="100%"
                                        style={{ objectFit: 'contain', objectPosition: 'center' }}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                              
                              {
                                (() => {
                                  if(item?.special_button) {
                                    switch(item.special_button) {
                                    case 'Çözücü':
                                      if(enet_values['Çözücü'].available) return (
                                        <div className="flex justify-center items-center">
                                          <DPIcon icon={ item?.icon ?? 'cozucu' } className={`text-2xl ${item?.icon ? '' : 'mr-1'}`} />
                                          <div className={`flex flex-col justify-start ${item?.icon ? 'ps-3' : 'px-1'} text-[13px]`}>
                                            <strong className="leading-relaxed" dangerouslySetInnerHTML={
                                              purify(_e('Çözücü\'de :count', {
                                                'count': enet_values['Çözücü'].count
                                              }))
                                            }></strong>
                                            <span className="text-[10px] leading-snug" dangerouslySetInnerHTML={ purify(_e('Soru Sorma Hakkı')) }></span>
                                          </div>
                                        </div>
                                      );
                                      break;
                                    case 'Şimdi Anladım':
                                      if(enet_values['Şimdi Anladım'].available) return (
                                        <div className="flex justify-center items-center">
                                          { item?.icon && (<DPIcon icon={ item?.icon } className="text-2xl" />) }
                                          <div className={`flex flex-col justify-start ${item?.icon ? 'ps-3' : 'px-1'} text-[13px]`}>
                                            <strong className="leading-relaxed" dangerouslySetInnerHTML={ purify(_e('Şimdi Anladım\'da')) }></strong>
                                            <span className="text-[10px] leading-snug" dangerouslySetInnerHTML={
                                              purify(_e('<strong>:meeting_count</strong> Express Özel Ders Hakkı', {
                                                'meeting_count': enet_values['Şimdi Anladım'].meeting_count
                                              }))
                                            }></span>
                                          </div>
                                        </div>
                                      );
                                      break;
                                    case 'Koçum Yanımda':
                                      if(enet_values['Koçum Yanımda'].available) return (
                                        <div className="flex justify-center items-center">
                                          { item?.icon && (<DPIcon icon={ item?.icon } className="text-2xl" />) }
                                          <div className={`flex flex-col justify-start ${item?.icon ? 'ps-3' : 'px-1'} text-[13px]`}>
                                            <strong className="leading-relaxed" dangerouslySetInnerHTML={ purify(_e('Koçum Yanımda\'da')) }></strong>
                                            <span className="text-[10px] leading-snug" dangerouslySetInnerHTML={
                                              purify(_e('<strong>:interview_count</strong> Görüşme ve <strong>:message_count</strong> Mesaj Hakkı', {
                                                'interview_count': enet_values['Koçum Yanımda'].interview_count,
                                                'message_count': enet_values['Koçum Yanımda'].message_count
                                              }))
                                            }></span>
                                          </div>
                                        </div>
                                      );
                                      break;
                                    case 'Online Eğitim':
                                      if(enet_values['Koçum Yanımda'].available) return (
                                        <div className="flex justify-center items-center">
                                          { item?.icon && (<DPIcon icon={ item?.icon ?? 'ai' } className="text-2xl" />) }
                                          <div className={`flex flex-col justify-start ${item?.icon ? 'ps-3' : 'px-1'} text-[13px]`}>
                                            <strong className="leading-relaxed" dangerouslySetInnerHTML={ purify(_e('Yapay Zeka')) }></strong>
                                            <span className="text-[10px] leading-snug" dangerouslySetInnerHTML={ purify(_e('Destekli Akıllı Test')) }></span>
                                          </div>
                                        </div>
                                      );
                                      break;
                                    }
                                  }

                                  if(item?.bg_image_url) {
                                    return (
                                      <div className="relative flex justify-center items-stretch w-full max-w-[100px]">
                                        <div className="relative w-full">
                                          <Image
                                            src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/files/attribute/${item.bg_image_url}`}
                                            alt={item?.caption ? `${item.caption} ${item.subcap ?? ''}` : 'Doping Hafıza'}
                                            fill={true}
                                            sizes="100%"
                                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }

                                  return (
                                    <div className="flex justify-center items-center">
                                      { item?.icon && (<DPIcon icon={ item?.icon } className="text-2xl" />) }
                                      <div className={`flex flex-col justify-start ${item?.icon ? 'ps-3' : 'px-1'} text-[13px]`}>
                                        <strong className="leading-relaxed">{
                                          (() => {
                                            let val: number|string = item?.caption ?? '';

                                            if(
                                              item?.dynamic_value
                                              && Object.keys(productDynamicValues).includes(item.dynamic_value)
                                              && data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
                                            ) {
                                              val = data.curriculums.summary?.[productDynamicValues[item.dynamic_value]] ?? 0;
                                              if([ 'İçerik Süresi', 'İnteraktif İçerik Süresi' ].includes(item.dynamic_value)) {
                                                val = formatNumber(durationMinutes(val as number)) + ' dk';
                                              } else {
                                                val = formatNumber(val as number);
                                              }
                                            }

                                            return val;
                                          })()
                                        }</strong>
                                        <span className="text-[10px] leading-snug" dangerouslySetInnerHTML={ purify(item.subcap) }></span>
                                      </div>
                                    </div>
                                  );
                                })()
                              }

                              {
                                item?.video_url && (
                                  <VideoDialog src={item.video_url} poster={item?.video_poster_url ?? ''} deselectVideo={deselectProperty} dialogActive={item?.dialogActive} />
                                )
                              }
                            </div>
                          </SwiperSlide>
                        );
                      })
                    }
                  </Swiper>

                  <div className="custom-buttons hidden lg:flex justify-between h-0 overflow-visible absolute -left-1 -right-1 top-[50%] -translate-y-[18px] z-10">
                    <div className="swiper-button-prev-properties">
                      <DPIcon className="text-4xl text-white leading-[0] cursor-pointer" icon="arrow-left" />
                    </div>
                    <div className="swiper-button-next-properties">
                      <DPIcon className="text-4xl text-white leading-[0] cursor-pointer" icon="arrow-right" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          )
        }
      </div>
    </div>
  );
}
